/* login-signup start */

.btns.btn {
	border: 1px solid #15a756;
	color: #15a756;

}

.btns.btn:hover {
	color: white;
	background-color: #15a756;
}

select[id=SelectGroup]:focus {
	border-color: #18ba60;
}

input[type=password]:focus {
	border-color: #18ba60;
}

select[id=Projects]:focus {
	border-color: #18ba60;
}

input[type=phone]:focus {
	border-color: #18ba60;
}

textarea[id=Textarea]:focus {
	border-color: #18ba60;
}

input[type=text]:focus {
	border-color: #18ba60;
}

input[type=email]:focus {
	border-color: #18ba60;
}

textarea[id=message]:focus {
	border-color: #18ba60;
}

.marquee {
	width: 720px;
	height: 400px;
	overflow: hidden;
	position: relative;
	border: 2px solid #18ba60;
	box-shadow: 2px 2px 2px #18ba60;
}

.marquee-text {
	position: absolute;
	width: 93%;
	height: 100%;
	animation: marquee 16s linear infinite;
	margin-left: 20px;
}

@keyframes marquee {
	0% {
		top: 20%;
	}

	100% {
		top: -100%;
	}
}

.marquee:hover .marquee-text {
	animation-play-state: paused;
}

@media only screen and (max-width: 600px) {
	.marquee {
		margin-top: 20px;
		width: 95%;
	}

	.marquee-text {
		text-align: justify;
		width: 90%;
	}
}


.sign-img{
	padding: 0px 0px 0px 0px;
}
.sign-shadow{

	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
