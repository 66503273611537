/* contact start */
label {
	margin-bottom: 8px;
}

input[type=text]:focus {
	border-color: #18ba60;
}

input[type=email]:focus {
	border-color: #18ba60;
}

textarea[id=message]:focus {
	border-color: #18ba60;
}

.gradient-brand-color {
	background-image: linear-gradient(to left top, #18ba60, #31bd6d, #44c179, #53c484, #62c78f);
}

.contact-info__wrapper {
	overflow: hidden;
	border-radius: .625rem .625rem 0 0;
}

@media (min-width: 1024px) {
	.contact-info__wrapper {
		border-radius: 0 .625rem .625rem 0;
		padding: 5rem !important
	}
}

.contact-info__list span.position-absolute {
	left: 0
}

.z-index-101 {
	z-index: 101;
}

.list-style--none {
	list-style: none;
}

.contact__wrapper {
	background-color: #fff;
	border-radius: 0 0 .625rem .625rem
}

@media (min-width: 1024px) {
	.contact__wrapper {
		border-radius: .625rem 0 .625rem .625rem
	}
}

@media (min-width: 1024px) {
	.contact-form__wrapper {
		padding: 5rem !important
	}
}

.shadow-lg,
.shadow-lg--on-hover:hover {
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* contact end */