@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
* {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  
}

.nav_ul li {
  /* padding: 0 0.6rem; */
 margin-left: 2px;
}

.nav_ul a {
  font-size: 1.05rem;
  color: #000 !important;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.navbar-brand img{
  width: 180px;
}

.btn1 {
  border: 0;
  padding: 0.5rem 1.8rem;
  color: #fff;
  background-color: #01193c;
}

.btn2 {
  border: 0;
  padding: 0.5rem 1.2rem;
  color: #fff;
  background-color: #048221;
}
.Footer {
  /* padding: 2rem 0 0 0; */
  border-top: 1px solid;
  margin-top: 50px;
}

.ft-1 h3 {
  font-weight: 700;
  font-family: cursive;
  letter-spacing: 2px;
  padding: 0.5rem 0;
}

.ft-1 span {
  color: #048221;
}

.ft-1 p {
  padding: 0rem 5rem 0.8rem 0;
  font-weight: 500;
}

.footer-icons i {
  padding: 0.4rem 0.5rem;
  background: #e1e1e1;
  color: #048221;
  margin: 0 0.5rem;
  border-radius: 50%;
}

.footer-icons i:hover {
  background: #048221;
  color: #fff;
  transition: 0.6s;
  cursor: pointer;
}


.ft-2 ul {
  list-style: none;
  padding-left: 0;
}

.ft-2 ul li {
  padding: 0.35rem 0;
  font-weight: 500;
}

.ft-2 ul a {
  text-decoration: none;
  color: #000;
  font-size: 1.06rem;
}

.ft-3 p {
  font-weight: 500;
  padding: 0.1rem 0;
  font-size: 1.06rem;
}

.ft-3 i {
  padding-right: 0.5rem;
}

.Last-footer {
  text-align: center;
  padding: 0.5rem 0;
  margin-top: 20px;
}

.Last-footer p {
  margin-bottom: 0;
  font-size: 1.2rem;
  color: white;
  font-weight: 700;
}
.news{
  width: 330px;
}