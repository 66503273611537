/* about us start */
@media (max-width: 480px) {
	.caro1.carousel-item {
	  height: 300px;
	  background-size: cover;
	  background-position: center;
	  background-repeat: no-repeat;
	}
  }
  
  a:link, a:active, a:visited, a:hover {
    color: white;
}  
.textset{
	text-align: justify;
}
  /* about us end*/

.red{width:80px;height:80px;display:none;background-color:red;}
.red{width:80px;height:80px;display:none;background-color:red;}
.red{width:80px;height:80px;display:none;background-color:red;}
.smalln{
	color:green;
	font-weight: 500;
}