/* Google Fonts - Poppins */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");


.conse {
	position: fixed;
	bottom: 0%;
	right: 0%;
	transform: translateY(-50%);
	display: flex;
	flex-direction: column-reverse;
	align-items: center;
  }
  
  .media-icons {
	position: fixed;
	bottom: 55px;
	right: 0%;
	transform: translateX(100%);
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	justify-content: center;
	background-color: #fff;
	padding: 6px;
	border-radius: 6px;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
	transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
	/* z-index: 2001; Ensure it appears above other content */
  }
  
  .close-btn {
	position: fixed;
	bottom: 10px; /* Adjust as needed */
	right: 10px; /* Adjust as needed */
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
	width: 40px;
	border-radius: 50%;
	color: #fff;
	font-size: 18px;
	background-color: #8e36ff;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
	cursor: pointer;
	transform: rotate(45deg);
	transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
	/* z-index: 2001; Ensure it appears above .media-icons */
  }
  
  .close-btn.open {
	transform: rotate(90deg);
	background-color: #de0611;
  }
  
  .close-btn.open ~ .media-icons {
	transform: translateX(0);
  }
  
  .media-icons a {
	text-decoration: none;
	position: relative;
	height: 35px;
	width: 35px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 6px;
	margin: 6px;
  }
  
  .media-icons a i {
	color: #fff;
  }
  
  .media-icons a .tooltip {
	position: absolute;
	right: 55px;
	font-size: 14px;
	font-weight: 400;
	pointer-events: none;
	background-color: #fff;
	padding: 4px 8px;
	border-radius: 4px;
	transform: translateY(-25px);
	opacity: 0;
	transition: all 0.2s linear;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
	/* z-index: 20002; */
  }
  
  .media-icons a:hover .tooltip {
	opacity: 1;
	transform: translateY(0);
  }
  
  .media-icons a .tooltip::before {
	content: "";
	position: absolute;
	height: 10px;
	width: 10px;
	top: 50%;
	right: -5px;
	transform: translateY(-50%) rotate(45deg);
	background-color: #fff;
  }
  