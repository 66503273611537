@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700,800,900');

body{
	font-family: 'Poppins', sans-serif;
	font-weight: 300;
	font-size: 15px;
	line-height: 1.7;
	color: #343434;
	background-color: #f1f2f6;
	overflow-x: hidden;

}

a {
	cursor: pointer;
}
a:hover {
	text-decoration: none;
}

.section{
  position: relative;
  width: 100%;
  display: block;
}
.f.ulll-height{
  min-height: 100vh;
}
.over-hide{
  overflow: hidden;
}
.padding-tb{
  padding: 100px 0;
}
[type="radio"]:checked,
[type="radio"]:not(:checked){
  position: absolute;
  left: -9999px;
}

.checkbox:checked + label,
.checkbox:not(:checked) + label{
  position: relative;
  cursor: pointer;
  margin: 0 auto;
  text-align: center;
  margin-right: 6px;
  margin-left: 6px;
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid #bdc3c7;
  background-size: cover;
  background-position: center;
  box-sizing: border-box;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  background-image: url('../asset/slider\ 4.avif');
  animation: border-transform 6s linear infinite alternate forwards;
    -webkit-animation-play-state: paused;
    -moz-animation-play-state: paused;
    animation-play-state: paused;
}
.checkbox.scnd + label{
  background-image: url('../asset/slider2.avif');
}
.checkbox.thrd + label{
  background-image: url('../asset/slider3.jpg');
}
.checkbox.foth + label{
  background-image: url('../asset/slider1.jpg');
}

.checkbox:checked + label{
  box-shadow: 0 8px 25px 0 rgba(16,39,112,.3);
  transform: scale(1.3);
    -webkit-animation-play-state: running;
    -moz-animation-play-state: running;
    animation-play-state: running;
}
@keyframes border-transform{
  0%,100% { border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%; } 
  14% { border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%; } 
  28% { border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%; } 
  42% { border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%; } 
  56% { border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%; } 
  70% { border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%; } 
  84% { border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%; } 
}

.slider-height-padding {
  padding-top: 440px;
}

.ull {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  z-index: 100;
  padding: 0;
  margin: 0;
  list-style: none;
}
.ull li {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: block;
  z-index: 100;
  padding: 0;
  margin: 0;
  list-style: none;
  height: 400px;
  border: 5px solid #bdc3c7;
  background-size: cover;
  background-position: center;
  background-image: url('../asset/slider\ 4.avif');
  border-radius: 50%;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  font-weight: 900;
  font-size: 16px;
  letter-spacing: 2px;
  line-height: 2.7;
  color: #343434;
  writing-mode: vertical-rl;
  opacity: 0;
  pointer-events: none;
  box-shadow: 0 8px 25px 0 rgba(16,39,112,.1);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.ull li span {
  mix-blend-mode: difference;
}
.ull li:nth-child(2) {
  background-image: url('../asset/slider2.avif');
}
.ull li:nth-child(3) {
  background-image: url('../asset/slider3.jpg');
}
.ull li:nth-child(4) {
  background-image: url('../asset/slider1.jpg');
}


.checkbox.frst:checked ~ .ull li:nth-child(1) {
  opacity: 1;
  pointer-events: auto;
  border-radius: 16px;
}
.checkbox.scnd:checked ~ .ull li:nth-child(2) {
  opacity: 1;
  pointer-events: auto;
  border-radius: 16px;
}
.checkbox.thrd:checked ~ .ull li:nth-child(3) {
  opacity: 1;
  pointer-events: auto;
  border-radius: 16px;
}
.checkbox.foth:checked ~ .ull li:nth-child(4) {
  opacity: 1;
  pointer-events: auto;
  border-radius: 16px;
}

.logo {
	position: absolute;
	top: 30px;
	right: 30px;
	display: block;
	z-index: 100;
	transition: all 250ms linear;
}
.logo img {
	height: 26px;
	width: auto;
	display: block;
}



@media (max-width: 767px) {
  .slider-height-padding {
    padding-top: 340px;
  }
  .ull li {
    height: 300px;
    font-size: 13px;
    letter-spacing: 1px;
  }
}

@media (max-width: 575px) {
  .slider-height-padding {
    padding-top: 240px;
  }
  .ull li {
    height: 200px;
  }
}

.wrapper{
  display: flex;
  height: 95px;
}
.wrapper .static-txt{
  color: #048221;
  font-size: 50px;
  font-weight: 400;
}

.wrapper .dynamic-txts{
  /* margin-left: 15px; */
  padding-left: 10px;
  height: 90px;
  line-height: 90px;
  overflow: hidden;
}
.dynamic-txts li{
  list-style: none;
  color: #048221;
  font-size: 52px;
  font-weight: 500;
  position: relative;
  top: 0;
  animation: slide 12s steps(4) infinite;
}
@keyframes slide {
  100%{
    top: -360px;
  }
}
.dynamic-txts li span{
  position: relative;
  margin: 5px 0;
  line-height: 90px;
}
.dynamic-txts li span::after{
  content: "";
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #f1f1f1;
  background-image: linear-gradient( #e9f3e8, #e8f7e7);
  border-left: 2px solid #048221;
  animation: typing 3s steps(10) infinite;
}
@keyframes typing {
  40%, 60%{
    left: calc(100% + 30px);
  }
  100%{
    left: 0;
  }
}
.para{
  font-size: 18px;
  margin-bottom: 8px;
  text-align: justify;
  font-weight: 500;
}
.stext{
  font-weight: 500;
  text-align: justify;
  text-transform: capitalize;
  
}

.box{
  margin-top: 20px;
  width: 380px;
  height: 80px;
  border: 2px solid rgb(228, 233, 229);
  box-shadow: 0 2.5rem 2rem -2rem hsla(200, 51%, 20%, 0.161);
  border-radius: 20px;

}
.box-inner{
  margin: 13px 0;
}
.box span{
  font-size: 18px;
  margin: auto 0;
  margin-left: 10px;
}
.box input{
  width: 210px;
  border-radius:0 0 0 0;
  height: 50px;
  border: none;
  margin-left: 6px;
  background: transparent;
}
input:focus{
outline: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.box button{
  width: 100px;
  border-radius:10px ;
  height: 50px;
}

/* Instagram, Email, and Facebook icons */
.icons {
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 9999;
}

.icons a {
  color: #25d366; /* Same color as WhatsApp */
  font-size: 45px; /* Same size as WhatsApp icon */
  margin-bottom: 10px;
  display: block;
  text-decoration: none;
}

.icons a:hover {
  opacity: 0.8;
  transform: scale(1.5);
  color: #25d366;
  
}
#insta {
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  -webkit-background-clip: text;
          /* Also define standard property for compatibility */
          background-clip: text;
  -webkit-text-fill-color: transparent;
  
  font-size: 45px; /* change this to change the size*/
  
}
@media only screen and (max-width: 600px) {
  .icons {
  display: none;
  }
}
