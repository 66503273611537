.border-green{ 
	border: 2px solid green ;
}
.main-color{
	 color: #15a756;
}
.font-size{
	 font-Size: 17px;
}
.text-view{
	text-align: justify;
	font-size: 17px;

}
