.main-head {
  background-color: #15a756;
  color: white;
  box-shadow: 0 4px 6px 0 rgba(22, 22, 26, 0.18);

}

.head-width {
  width: 71%;
  margin: auto;
}

@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap");


.pricing {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .plan {
    background-color: #fff;
    padding: 2.5rem;
    margin: 12px;
    border-radius: 5px;
    text-align: center;
    transition: 0.3s;
    cursor: pointer;

    h2 {
      font-size: 22px;
      margin-bottom: 12px;
    }

    .price {
      margin-bottom: 1rem;
      font-size: 30px;
    }

    ul.features {
      list-style-type: none;
      text-align: left;

      li {
        margin: 8px;

        .fas {
          margin-right: 4px;
        }

        .fa-check-circle {
          color: #15a756;
        }

        .fa-times-circle {
          color: #eb4d4b;
        }
      }
    }

    button {
      border: none;
      width: 100%;
      padding: 12px 35px;
      margin-top: 1rem;
      border-radius: 5px;
      cursor: pointer;
      font-size: 16px;
      background-color: white;
      color:#15a756 ;
      border:2px solid #15a756;
      transition: 0.3s;
    }
    button:hover{
      background-color: #15a756;
      color: #fff;

    }

    &.popular {
      border: 2px solid #15a756;
      position: relative;
      transform: scale(1.08);

      span {
        position: absolute;
        top: -20px;
        left: 50%;
        transform: translateX(-50%);
        background-color: #15a756;
        color: #fff;
        padding: 4px 18px;
        font-size: 18px;
        border-radius: 5px;
      }
    }

    &:hover {
      box-shadow: 5px 7px 67px -28px rgba(3, 3, 3, 0.37);
    }
  }
}

.margin-bottom {
  margin-bottom: 20px;
}
