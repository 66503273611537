
.nav-tabs-custom .nav-item .nav-link.active {
    color: #18ba60;
	background: transparent;
}
.nav-tabs-custom .nav-item .nav-link {
    border: none;
}
.text-muted {
    color: #8ca4c0!important;
}
.nav-tabs-custom .nav-item {
    position: relative;
    color: #057236;
}
.nav-tabs-custom .nav-item .nav-link.active:after {
    -webkit-transform: scale(1);
    transform: scale(1);
}
.nav-tabs-custom .nav-item .nav-link::after {
    content: "";
    background: #18ba60;
    height: 2px;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -1px;
    -webkit-transition: all 250ms ease 0s;
    transition: all 250ms ease 0s;
    -webkit-transform: scale(0);
    transform: scale(0);
}