@import url('https://fonts.googleapis.com/css?family=Exo:400,700');



.context {
    width: 100%;
    position: absolute;
    top:50vh;
    
}

.context .head{
    text-align: center;
    color: #fff;
    font-size: 50px;
}


.area{
     /* height:; */
  background-color: #f1f1f1;
  background-image: linear-gradient( whitesmoke, #c0ebbd);
    width: 100%;
    height:100vh;
 
    
   
}

.round{
    position:relative;
    top: -20vh;
    left: 0;
    width: 60%;
    height: 90%;
    overflow: hidden;
}

.round .float{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background:#4e54c8;
    animation: animate 25s linear infinite;
    bottom: -150px;  
}

.round .float:nth-child(1){
    left: 25%;
    width: 40px;
    height: 40px;
    animation-delay: 0s;
}


.round .float:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.round .float:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.round .float:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.round .float:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.round .float:nth-child(6){
    left: 75%;
    width: 40px;
    height: 40px;
    animation-delay: 3s;
}

.round .float:nth-child(7){
    left: 35%;
    width: 70px;
    height: 70px;
    animation-delay: 7s;
}

.round .float:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.round .float:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.round .float:nth-child(10){
    left: 85%;
    width: 70px;
    height: 70px;
    animation-delay: 0s;
    animation-duration: 11s;
}



@keyframes animate {

    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}
.img_logo{
border-radius: 10px;
}
